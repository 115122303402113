import React from 'react'
import styled, { withTheme } from 'styled-components'

import { COLORS } from 'COLORS'

const Wrapper = styled.svg`
  position: absolute;
  right: -5px;
  bottom: 4px;
  margin-left: 5px;
`

const UnlockNavIcon = ({ theme }) => {
  return (
    <Wrapper width="20" height="19" viewBox="0 0 20 19">
      <g fill={COLORS.grey400} fillRule="evenodd">
        <path
          stroke="#232323"
          strokeWidth="4"
          d="M16 10V8l-.007-.117C15.936 7.386 15.513 7 15 7l-.117.007c-.497.057-.883.48-.883.993v2h2zm-4-2l.005-.176C12.096 6.249 13.402 5 15 5c1.657 0 3 1.343 3 3v2h1c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1h-8c-.552 0-1-.448-1-1v-7c0-.552.448-1 1-1h1V8z"
        />
        <path
          fill={theme.colors.primarySidenav}
          d="M16 10V8l-.007-.117C15.936 7.386 15.513 7 15 7l-.117.007c-.497.057-.883.48-.883.993v2h2zm-4-2l.005-.176C12.096 6.249 13.402 5 15 5c1.657 0 3 1.343 3 3v2h1c.552 0 1 .448 1 1v7c0 .552-.448 1-1 1h-8c-.552 0-1-.448-1-1v-7c0-.552.448-1 1-1h1V8z"
        />
      </g>
    </Wrapper>
  )
}
const CNUnlockNavIcon = () => {
  return (
    <Wrapper width="20" height="19" viewBox="0 0 20 19">
      <g fill={COLORS.grey400} fillRule="evenodd">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.625 16.875H5.375C4.75 16.875 4.25 16.375 4.25 15.75V10C4.25 9.375 4.875 8.875 5.375 8.875H14.5C15.25 8.875 15.625 9.375 15.625 10V15.75C15.75 16.375 15.25 16.875 14.625 16.875Z"
            fill="#F57706"
          />
          <path
            d="M7.125 8.25V6.625C7.125 5.25 8.125 4.25 9.5 4.25H10.5C11.75 4.25 12.875 5.25 12.875 6.625V8.25H14V6.625C14 4.625 12.375 3.125 10.5 3.125H9.5C7.5 3.125 6 4.75 6 6.625V8.25H7.125Z"
            fill="#F57706"
          />
        </svg>
      </g>
    </Wrapper>
  )
}
const wrappedComponent = withTheme(UnlockNavIcon)
export { wrappedComponent as UnlockNavIcon, CNUnlockNavIcon }
