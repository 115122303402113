import { TRANSITION_TIMING } from 'constants/sidebar'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from 'COLORS'

const Wrapper = styled.svg`
  position: absolute;
  opacity: ${props => (props.collapsed ? 0 : 1)};
  transition: opacity 0.3s ${TRANSITION_TIMING};
  bottom: 2px;
  right: -9px;
  padding-left: 5px;
`

const LockNavIcon = ({ collapsed }) => {
  return (
    <Wrapper collapsed={collapsed} width="24" height="21" viewBox="0 0 24 21">
      <g fill={COLORS.grey400} fillRule="evenodd">
        <path
          id="unlocked"
          stroke="#232323"
          strokeWidth="2"
          d="M19 9V7v2h4.004l-.01-2.205c-.053-1.065-.523-2.02-1.25-2.706C21.027 3.414 20.062 3 19 3c-1.105 0-2.105.448-2.828 1.172C15.448 4.895 15 5.895 15 7v2h-4c-.552 0-1.052.224-1.414.586C9.224 9.948 9 10.448 9 11v7c0 .552.224 1.052.586 1.414.362.362.862.586 1.414.586h8c.552 0 1.052-.224 1.414-.586.362-.362.586-.862.586-1.414v-7c0-.552-.224-1.052-.586-1.414C20.052 9.224 19.552 9 19 9h0z"
        />
      </g>
    </Wrapper>
  )
}
const CNLockNavIcon = ({ collapsed }) => {
  return (
    <Wrapper collapsed={collapsed} width="24" height="21" viewBox="0 0 24 21">
      <g fill={COLORS.grey400} fillRule="evenodd">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.625 16.875H5.37503C4.75003 16.875 4.25003 16.375 4.25003 15.75V10C4.25003 9.375 4.87503 8.875 5.37503 8.875H14.5C15.25 8.875 15.625 9.375 15.625 10V15.75C15.75 16.375 15.25 16.875 14.625 16.875Z"
            fill="#9AA8AE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.125 5.625V7.25H6V5.625C6 3.75 7.5 2.125 9.5 2.125H10.5C12.1739 2.125 13.6486 3.32051 13.9456 5H12.7937C12.514 3.95725 11.5512 3.25 10.5 3.25H9.5C8.125 3.25 7.125 4.25 7.125 5.625Z"
            fill="#9AA8AE"
          />
        </svg>
      </g>
    </Wrapper>
  )
}
LockNavIcon.propTypes = {
  collapsed: PropTypes.bool.isRequired
}
CNLockNavIcon.propTypes = {
  collapsed: PropTypes.bool.isRequired
}
export { LockNavIcon, CNLockNavIcon }
